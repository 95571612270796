<template>
  <div class="inline-flex">
    <FormInput
        :size="inputSize"
        class="max-w-[110px]"
        placeholder="40"
        v-model="width"
        input-size="sm"
        @keydown.enter="calculateQty"
    >
      <template #suffix>
        <span class="text-gray-500 lowercase">{{ unitName }}</span>
      </template>
    </FormInput>

    <FormInputCounter
        class="max-w-[150px]"
        v-model="qty"
        :min="1"
        :max="maxQtyForProduct"
        :input-size="inputSize"
        @change="calculateQty"
    />
  </div>
</template>

<script setup>

const props = defineProps(['currency', 'price', 'maxQtyForProduct', 'unitName', 'size'])

const qty = defineModel('qty')
const width = defineModel('width')
const totalQty = defineModel('totalQty')

const inputSize = computed(() => props.size ? props.size : 'sm')

const calculateQty = () => {

  if(!qty.value || typeof qty.value === 'undefined') {
    qty.value = 1;
  }

  if(!width.value || typeof width.value === 'undefined') {
    width.value = 1;
  }

  totalQty.value = width.value * qty.value;
}

watch([qty, width], ([newQty, newWidth]) => {
  calculateQty()
})

</script>

<style scoped>

</style>